import { OutputBlockData, OutputData } from '@editorjs/editorjs'
import { decodeHTML } from 'entities'
import { validate as uuidValidate, v4 as uuidv4 } from 'uuid'

import { BlockType } from '~/components/board/Editor/plugins/types'
import { checkPlaceIsSaved } from '~/components/places/utils'
import { AutoPredictions } from '~/components/shared/googlemaps/types'
import { Typography } from '~/components/shared/text/Typography'
import {
  BoardDetails,
  BoardDetailsWithRefs,
  BoardLinkBlockType,
  BoardNodeType,
  BoardNodesType,
  DividerBlockType,
  LinkBlockType,
  PhotoBlockType,
  PlaceBlockType,
  TextBlockType,
  VideoBlockType,
  WeatherBlockType,
} from '~/endpoints/model'
import { PlaceSummaryType } from '~/endpoints/model/places'
import {
  createPlace,
  fetchAllPlaceSummary,
  fetchGoogleMapPlaceDetails,
  fetchPlacePredictions,
} from '~/endpoints/places'
import { calculateDiscountedPrice, isEmptyObject } from '~/utils/helper'
import { captureSentryException } from '~/utils/sentry'
import { nextSessionToken, sessionToken } from '~/utils/sessionToken'
import { priceFormatter } from '~/utils/string'

const map = [
  {
    nodeType: 'heading1',
    blockType: 'h1',
  },
  {
    nodeType: 'heading2',
    blockType: 'h2',
  },
  {
    nodeType: 'heading3',
    blockType: 'h3',
  },
  {
    nodeType: 'body1',
    blockType: 'body',
  },
]

/**
 * @param type
 * @returns mapped type
 */
export function mapNodeTypeToBlockType(type: string) {
  return map.find(item => item.nodeType === type)?.blockType || ''
}

/**
 * @param type
 * @returns mapped type
 */
export function mapBlockTypeToNodeType(type: string) {
  return map.find(item => item.blockType === type)?.nodeType || ''
}

/** Helper function that can be used to throw an exception from a nullable-coalescence. */
export function assertNever(e: Error): never {
  throw e
}

/** A type guard for OutputBlockData that could be a BlockType */
export function isBlockType(block: OutputBlockData): block is BlockType {
  switch (block.type) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'body':
    case 'divider':
    case 'guide':
    case 'image':
    case 'webLink':
    case 'place':
    case 'video':
    case 'weather':
      return true
    default:
      return false
  }
}

/**
 * @param boardDetails
 * @returns OutputData
 */
export function mapNodeToEditorOutputData(
  boardDetails: BoardDetailsWithRefs
): OutputData | undefined {
  if (boardDetails.nodes.length === 0) {
    return undefined
  }

  const boardRefs = boardDetails.refs
  const nodeUids = new Set(boardDetails.nodes.map(it => it.uid))

  const blocks: OutputBlockData[] = boardDetails.blocks
    .filter(block => {
      const found = nodeUids.has(block)
      if (!found) {
        captureSentryException(
          new Error(`Node not found for block ${block} in board ${boardDetails.token}`)
        )
      }
      return found
    })
    .map(block => {
      // Since we have filtered out the blocks we couldn't find, it is safe to throw an error if we
      // can't find a node in the original board details.
      const node =
        (boardDetails.nodes.find(el => el.uid === block) as BoardNodeType) ??
        // Without this assertion, the code bellow that extracts node data will throw a less helful error
        assertNever(new Error(`Node not found for block ${block} in board ${boardDetails.token}`))

      switch (node.type) {
        case 'text': {
          const textNode = node as TextBlockType
          return {
            id: textNode.uid,
            type: mapNodeTypeToBlockType(textNode.style),
            data: {
              text: textNode.text,
            },
          }
        }
        case 'photo': {
          const photoNode = node as PhotoBlockType
          return {
            id: photoNode.uid,
            type: 'image',
            data: {
              url: photoNode.url,
            },
          }
        }
        case 'video': {
          const videoNode = node as VideoBlockType
          return {
            id: videoNode.uid,
            type: 'video',
            data: {
              url: videoNode.url,
              aspectRatio: videoNode.aspectRatio,
            },
          }
        }
        case 'link': {
          const linkNode = node as LinkBlockType
          return {
            id: linkNode.uid,
            type: 'webLink',
            data: {
              url: linkNode.link,
              title: linkNode.title,
              imageUrl: linkNode.icon,
            },
          }
        }
        case 'board-link': {
          const boardNode = node as BoardLinkBlockType
          return {
            id: boardNode.uid,
            type: 'guide',
            data: boardRefs.boards?.[boardNode.token],
          }
        }
        case 'place': {
          const placeNode = node as PlaceBlockType
          return {
            id: placeNode.uid,
            type: 'place',
            data: boardRefs.places?.[placeNode.place],
          }
        }
        case 'divider': {
          const dividerNode = node as DividerBlockType
          return {
            id: dividerNode.uid,
            type: 'divider',
            data: {},
          }
        }
        case 'weather': {
          const weatherNode = node as WeatherBlockType
          return {
            id: weatherNode.uid,
            type: 'weather',
            data: {
              lat: weatherNode.lat,
              long: weatherNode.long,
              name: weatherNode.name,
            },
          }
        }
        default:
          // Create a generic node by stuffing the full node into the data section of the block as
          // this will allow us to display an error card in the board, as well as preserve the
          // original node contents for sending back to the API.
          //
          // This acts as a temporary storage location to help prevent loosing data when the webapp
          // sees unsupported datas types.
          return {
            id: node.uid,
            type: node.type,
            data: {
              ...node,
            },
          }
      }
    })
    .filter(it => !!it)
  return {
    blocks,
  }
}

export function convertEditorDataToBoardDetails(
  editorData: OutputData,
  boardDetails: BoardDetailsWithRefs
): BoardDetailsWithRefs {
  const payload: BoardDetailsWithRefs = {
    ...boardDetails,
  }
  // Replace editor.js generated block ID with uuid.
  const dataBlocks =
    editorData?.blocks
      .filter(block => !isEmptyObject(block.data) || block.type === 'divider') // no data for divider block
      .map(block => (uuidValidate(block.id || '') ? block : { ...block, id: uuidv4() })) || []
  payload.blocks = dataBlocks.map(block => block.id || '')
  payload.nodes = dataBlocks.map(block => {
    const currentNode = boardDetails.nodes.find(node => node.uid === block.id)
    const baseNode = {
      id: currentNode?.id,
      uid: currentNode?.uid || block.id,
      lockVersion: currentNode?.lockVersion,
    }

    if (!isBlockType(block)) {
      console.warn('Trying to decode an unknown output block data type: %o', block)
    }

    switch (block.type) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'body':
        return {
          ...baseNode,
          type: 'text',
          style: mapBlockTypeToNodeType(block.type),
          // Convert html entities into plain string.
          text: decodeHTML(block.data.text ?? ''),
        }
      case 'image':
        return {
          ...baseNode,
          type: 'photo',
          url: block.data.url,
        }
      case 'video':
        return {
          ...baseNode,
          type: 'video',
          url: block.data.url,
          aspectRatio: block.data.aspectRatio,
        }
      case 'webLink':
        return {
          ...baseNode,
          type: 'link',
          link: block.data.url,
          title: block.data.title,
          icon: block.data.imageUrl,
        }
      case 'guide':
        return {
          ...baseNode,
          type: 'board-link',
          token: block.data.token,
        }
      case 'divider':
        return {
          ...baseNode,
          type: 'divider',
        }
      case 'place':
        return {
          ...baseNode,
          type: 'place',
          place: block.data.id,
        }
      case 'weather':
        return {
          ...baseNode,
          type: 'weather',
          lat: block.data.lat,
          long: block.data.long,
          name: block.data.name,
        }
      default:
        // If we did not recognise the block type, we can assume that the inverse mapping function
        // packed the node data inside the editor block data. We can just extract it back out to
        // send it back to the API as it was originally received.
        //
        // This will prevent us from destroying node data if we do not recognise/support the data
        // type yet.
        return {
          ...baseNode,
          ...block.data,
        }
    }
  }) as BoardNodesType
  return payload
}

export function getAllPlacesInSequence(boardDetails: BoardDetailsWithRefs): PlaceSummaryType[] {
  const {
    blocks,
    nodes,
    refs: { places: placesRefs },
  } = boardDetails
  const allPlaces = blocks.reduce((acc: PlaceSummaryType[], block) => {
    const currentNode = nodes.find(node => node.uid === block)
    if (currentNode?.type == 'place' && placesRefs) {
      acc.push(placesRefs[currentNode.place])
    }
    return acc
  }, [])

  return allPlaces
}

export const getBoardShareUrl = (boardToken: string): string => {
  return `${window.location.protocol}//${window.location.host}/guide/${boardToken}/view`
}

export const getUnlockButtonLabel = ({
  boardDetails,
  discountAmount,
  strikedPriceColor = "appPlaceholder.0",
  discountColor = "appGreen.0",
}: {
  boardDetails: BoardDetails
  discountAmount: number
  strikedPriceColor?: string
  discountColor?: string
}) => {
  const formattedPrice = priceFormatter(boardDetails?.price)
  const defaultLabel = `Buy for ${formattedPrice}`
  const saleAmount = boardDetails.discount?.amount || 0
  const discountValue = discountAmount > saleAmount ? discountAmount : saleAmount

  if (boardDetails?.price && discountValue > 0) {
    const discountedPrice = calculateDiscountedPrice(boardDetails.price, discountValue)
    return {
      label: (
        <Typography
          variant="button_medium"
          style={{ fontWeight: 500, fontSize: 20 }}
          className='flex items-center'
        >
          Buy for {discountedPrice.formatted}
          <Typography
            span
            ml={10}
            strikethrough
            variant="button_xsmall"
            color={strikedPriceColor}
            style={{ fontWeight: 500, fontSize: 16 }}
          >
            ${boardDetails.price}
          </Typography>
          <Typography
            span
            ml={5}
            variant="button_xsmall"
            color={discountColor}
            style={{ fontWeight: 500, fontSize: 16 }}
          >
            {discountValue}% off
          </Typography>
        </Typography>
      ),
      formattedPrice,
      rawPrice: discountedPrice.raw,
    }
  }

  return {
    label: defaultLabel,
    formattedPrice,
    rawPrice: boardDetails.price,
  }
}

export async function processAiGuide(
  guide: string,
  locationbias: string
): Promise<OutputBlockData[]> {
  // Select place names from guide
  const placeNames = guide
    .match(/{(.*?)}/g)
    ?.filter((place, index, self) => self.indexOf(place) === index)

  // Split guide by new line and filter out empty lines.
  const guideBlocks = guide.split('\n').filter(el => el)
  // Fetch all saved places
  const allPlaces = await fetchAllPlaceSummary()

  // Build place details record for all places included in the guide.
  const allPlaceDetails: Record<string, unknown> = {}
  if (placeNames) {
    await Promise.all(
      placeNames.map(async placeName => {
        try {
          const predictions = await fetchPlacePredictions(
            encodeURI(placeName),
            locationbias,
            sessionToken
          )

          const formattedPlacePredictions = predictions?.map((item: AutoPredictions) => {
            const { isSaved: isPlaceSaved, savedIndex } = checkPlaceIsSaved(
              allPlaces,
              item.place_id
            )
            return {
              ...item,
              isSaved: isPlaceSaved,
              savedIndex,
            }
          })

          if (formattedPlacePredictions.length) {
            nextSessionToken()
            const firstPredictionItem = formattedPlacePredictions[0]
            if (firstPredictionItem.isSaved) {
              const place = allPlaces[firstPredictionItem.savedIndex]
              allPlaceDetails[placeName] = place
            } else {
              const placeDetails = await fetchGoogleMapPlaceDetails(
                firstPredictionItem.place_id,
                sessionToken
              )

              if (placeDetails) {
                const createdPlaceDetails = await createPlace(placeDetails)
                if (createdPlaceDetails) {
                  allPlaceDetails[placeName] = createdPlaceDetails
                }
              }
            }
          }
        } catch (e) {
          console.error(`Error while fetching place details - ${JSON.stringify(e)}`)
        }
      })
    )
  }

  const blocks: OutputBlockData[] = []
  for (const block of guideBlocks) {
    if (block.startsWith('#') || block.startsWith('##')) {
      blocks.push({
        id: uuidv4(),
        type: 'h1',
        data: { text: block.replace(/#|{|}/g, '') },
      })
    } else {
      blocks.push({
        id: uuidv4(),
        type: 'body',
        data: { text: block.replace(/{|}/g, '') },
      })
    }

    // find all places included in the block and add place blocks.
    const places = placeNames?.filter(place => block.includes(place))
    if (places?.length) {
      for (const placeName of places) {
        if (allPlaceDetails[placeName]) {
          // Find if same place block already exists in blocks
          const isPlaceBlockExists = blocks.some(
            el =>
              el.type === 'place' &&
              el.data?.name === (allPlaceDetails[placeName] as PlaceSummaryType).name
          )
          if (!isPlaceBlockExists) {
            blocks.push({
              id: uuidv4(),
              type: 'place',
              data: allPlaceDetails[placeName],
            })
          }
        }
      }
    }
  }

  return blocks
}
