import { useEffect, useState } from 'react'

import { getBoardShareUrl } from '~/components/board/utils'
import { useAnalytics } from '~/context/SegmentProvider'

export const useGenerateShareUrl = (
  boardToken: string,
  sharedFrom: string,
  sharedTo: string,
  isShareSheet: string
) => {
  const [boardUrl, setBoardUrl] = useState('')
  const thatchSegment = useAnalytics()

  useEffect(() => {
    if (boardToken) {
      setBoardUrl(getBoardShareUrl(boardToken))
    }
  }, [boardToken])

  useEffect(() => {
    if (boardToken) {
      const getTrackedUrl = async () => {
        let currentUrl = getBoardShareUrl(boardToken)
        const currentUser = await thatchSegment.getCurrentUser()
        const currentUserId = currentUser.id() || currentUser.anonymousId()
        currentUrl = `${currentUrl}?s=${currentUserId}&s_f=${sharedFrom}&s_t=${sharedTo}&s_b=${isShareSheet}`
        setBoardUrl(currentUrl)
      }
      getTrackedUrl()
    }
  }, [boardToken, thatchSegment, boardUrl, sharedFrom, sharedTo, isShareSheet])

  return boardUrl
}
