import { Container, Sx } from '@mantine/core'
import React, { Fragment, ReactNode } from 'react'

import { BoardEventsEnum } from '~/analytics/BoardEvent'
import { useAnalytics } from '~/context'
import { useGenerateShareUrl } from '~/hooks/useGenerateShareUrl'
import { useHasMounted } from '~/hooks/useHasMounted'

export interface BoardShareButtonProps {
  customUrl?: string
  boardToken?: string
  children: ReactNode
  onCopy: () => void
  sharedFrom: 'e' | 'v' | 'p' | 's' | 'm_g'
  sharedTo:
    | 'fb'
    | 'wa'
    | 're'
    | 'tw'
    | 'li'
    | 'em'
    | 'me'
    | 'pi'
    | 'c_l'
    | 'ios_share'
    | 'android_share'
  isShareSheet: 'y' | 'n'
  sx?: Sx
}

export const BoardShareButton: React.FC<BoardShareButtonProps> = props => {
  const hasMounted = useHasMounted()
  const { boardToken, children, onCopy, sharedFrom, sharedTo, isShareSheet, customUrl } = props
  const boardUrl = useGenerateShareUrl(boardToken || '', sharedFrom, sharedTo, isShareSheet)
  const thatchSegment = useAnalytics()

  const handleOnCopy = () => {
    if (boardToken) {
      thatchSegment.trackBoardShareEvent(BoardEventsEnum.enum.ShareClicked, {
        boardToken: boardToken,
        sharedFrom: sharedFrom,
        sharedTo: sharedTo,
      })
    }
    onCopy()
  }

  if (!hasMounted) {
    return <>{children}</>
  }

  return (
    <Container
      sx={props.sx || {}}
      onClick={async () => {
        await navigator.clipboard.writeText(customUrl || boardUrl)
        handleOnCopy()
      }}
    >
      {children}
    </Container>
  )
}
